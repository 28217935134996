import axios from "@/libs/axios";
import { PracticesTypes } from "@/types/practicesTypes";

export  class HttpRepoPractices {
	
	static Practices = {
		getPractices(BaseFilters) {
			return [
				{
				  id: 94723,
				  practice: 'Family & Wellness Medicine, LLC',
				  address: '34004 16th Ave. S., Ste. 100 Federal Way, WA 98003',
				  email: 'wsomerlie1l@accuweather.com',
				  phone: '253-944-1223',
				  fax: '253-944-1255',
				  startDate: '08/05/19',
				  endDate: 'Active',
				  status: 'active'
				},
				{
				  id: 35471,
				  practice: 'Focus Health DPC',
				  address: '2522 N. Proctor St., #228 Tacoma, WA 98406',
				  email: '',
				  phone: '206-372-3194',
				  fax: '',
				  startDate: '08/05/19',
				  endDate: 'Active',
				  status: 'active'
				},
				{
				  id: 98756,
				  practice: 'The Manette Clinic',
				  address: '1100 Wheaton Way, Ste. F and G Bremerton, WA 98310',
				  email: 'wsomerlie1l@accuweather.com',
				  phone: '253-944-1223',
				  fax: '253-944-1255',
				  startDate: '05/04/19',
				  endDate: '09/22/21',
				  status: 'inactive'
				},
				{
				  id: 65487,
				  practice: 'Nurture Well Center',
				  address: '34004 16th Ave. S., Ste. 100 Federal Way, WA 98003',
				  email: 'wsomerlie1l@accuweather.com',
				  phone: '253-944-1223',
				  fax: '253-944-1255',
				  startDate: '08/05/19',
				  endDate: 'Active',
				  status: 'active'
				},
			];
			// return axios.get<ListResponseModel<ProcDetailTypes>>('/Practices/GetList',{params:BaseFilters})
		},
		addPractices(formData: PracticesTypes){
			// return axios.post<ModelResponse<ProcDetailTypes>>('/Practices/AddType',formData)
		},
		deletePractices(Id: string){
			// return axios.delete<ResponseModel>('/Practices/DeleteType',{params:{id:Id}})
		}
	}
	static PracticesContact = {
		getData(BaseFilters) {
			return [
				{
				  id: 9844,
					firstName: 'Marcus',
					lastName: 'Kenter',
					phone: [
						{
							number: '(708) 813-8989',
							type: 'work',
						}
					],
					email: [
						'AlexSBuckmaster@dayrep.com'
					],
					role: 'Role AAA',
					location: 'Location 1',
				},
				{
				  id: 4544,
					firstName: 'Cooper',
					lastName: 'Gouse',
					phone: [
						{
							number: '(401) 715-3344',
							type: 'personal',
						},
						{
							number: '(843) 461-5941',
							type: 'work',
						},
						{
							number: '(647) 894-6475',
							type: 'home',
						}
					],
					email: [
						'ChrisAGlasser@zoho.com',
						'ChrisAGlasser@gmail.com',
					],
					role: 'Role BBB',
					location: 'Location 1',
				},
			];
		}
	}
	static PracticesLocation = {
		getData(BaseFilters) {
			return [
				{
				  	id: 5125956494,
					firstName: 'Abram',
					lastName: 'Herwitz',
					phone: [
						{
							number: '(708) 813-8989',
							type: 'work',
						}
					],
					email: [
						'AlexSBuckmaster@dayrep.com'
					],
					fax: [
						'(123) 948-6548',
						'(999) 692-1234'
					],
					location: 'Focus Health 4th Street',
					addressOne: '1207 N 200th St., Ste. 102 Shoreline, WA 98133',
					addressTwo: null,
					city: null,
					state: null,
					country: null,
					zipCode: null,
					shortCode: '354',
					posCode: '03',
					billPatient: 'Yes',
					status: 'active',
				},
				{
				  	id: 2344575683,
					firstName: 'Abram',
					lastName: 'Herwitz',
					phone: [
						{
							number: '(401) 715-3344',
							type: 'personal',
						},
						{
							number: '(843) 461-5941',
							type: 'work',
						},
						{
							number: '(647) 894-6475',
							type: 'home',
						}
					],
					email: [
						'ChrisAGlasser@zoho.com',
						'ChrisAGlasser@gmail.com',
					],
					fax: [],
					location: 'Focus Health 8th Street',
					addressOne: '401 Olympia Ave. NE, #305 Box #48, Renton, WA 98056',
					addressTwo: null,
					city: null,
					state: null,
					country: null,
					zipCode: null,
					billPatient: 'Yes',
					shortCode: '751',
					posCode: '05',
					status: 'inactive',
				},
			];
		},
		async getOneData(BaseFilters) {
			let targetData;
			let data = [
				{
				  id: 5125956494,
					firstName: 'Abram',
					lastName: 'Herwitz',
					phone: [
						{
							number: '(708) 813-8989',
							type: 'work',
						}
					],
					email: [
						'AlexSBuckmaster@dayrep.com'
					],
					fax: [
						'(123) 948-6548',
						'(999) 692-1234'
					],
					location: 'Focus Health 4th Street',
					addressOne: '1207 N 200th St., Ste. 102 Shoreline, WA 98133',
					addressTwo: null,
					city: null,
					state: null,
					country: null,
					zipCode: null,
					shortCode: '354',
					posCode: '03',
					billPatient: 'Yes',
					status: 'active',
				},
				{
				  id: 2344575683,
					firstName: 'Abram',
					lastName: 'Herwitz',
					phone: [
						{
							number: '(401) 715-3344',
							type: 'personal',
						},
						{
							number: '(843) 461-5941',
							type: 'work',
						},
						{
							number: '(647) 894-6475',
							type: 'home',
						}
					],
					email: [
						'ChrisAGlasser@zoho.com',
						'ChrisAGlasser@gmail.com',
					],
					fax: [],
					location: 'Focus Health 8th Street',
					addressOne: '401 Olympia Ave. NE, #305 Box #48, Renton, WA 98056',
					addressTwo: null,
					city: null,
					state: null,
					country: null,
					zipCode: null,
					billPatient: 'Yes',
					shortCode: '751',
					posCode: '05',
					status: 'inactive',
				},
			];
			data.forEach((element) => {
				if(element.id == BaseFilters.SearchTerm) {
					// targetData.push(element);
					targetData = element;
				}
			});
			// console.log("targetData: ", targetData);
			return [targetData];
		}
	}
	static PracticesCorporation = {
		getData(BaseFilters) {
			return [
				{
				  	id: 4987,
					corporationName: 'Aya Healthcare',
					npi: '5125956494',
					shortCode: '3547',
					taxId: '877043018',
					addressOne: '1207 N 200th St., Ste. 102 Shoreline, WA 98133',
					addressTwo: null,
					city: null,
					state: null,
					country: null,
					zipCode: null,
					status: 'active',
				},
				{
				  	id: 2584,
					corporationName: 'Montefiore St. Luke’s Cornwall',
					npi: '2344575683',
					shortCode: '4670',
					taxId: '653757655',
					addressOne: '401 Olympia Ave. NE, #305 Box #48, Renton, WA 98056',
					addressTwo: null,
					city: null,
					state: null,
					country: null,
					zipCode: null,
					status: 'active',
				},
				{
				  	id: 1224,
					corporationName: 'Agile Physical Therapy',
					npi: '3185361061',
					shortCode: '5715',
					taxId: '155096886',
					addressOne: '19980 10th Ave. NE, Ste. 202 Poulsbo, WA 98370',
					addressTwo: null,
					city: null,
					state: null,
					country: null,
					zipCode: null,
					status: 'inactive',
				},
				{
				  	id: 5924,
					corporationName: 'Advanced Correctional, Inc.',
					npi: '5917619044',
					shortCode: '8574',
					taxId: '451766921',
					addressOne: '19987 1st Ave. S, Ste. 102 Normandy Park, WA 98148',
					addressTwo: null,
					city: null,
					state: null,
					country: null,
					zipCode: null,
					status: 'active',
				},
				{
				  	id: 9344,
					corporationName: 'Maven Clinic',
					npi: '7063995264',
					shortCode: '3214',
					taxId: '714897059',
					addressOne: '230 Grant Rd., Ste. B-2 East Wenatchee, WA 98802',
					addressTwo: null,
					city: null,
					state: null,
					country: null,
					zipCode: null,
					status: 'active',
				},
			];
		},
		async getOneData(BaseFilters) {
			let targetData;
			let data = [
				{
				  	id: 4987,
					corporationName: 'Aya Healthcare',
					npi: '5125956494',
					shortCode: '3547',
					taxId: '877043018',
					addressOne: '1207 N 200th St., Ste. 102 Shoreline, WA 98133',
					addressTwo: null,
					city: 'Ellensburg',
					state: 'Washington',
					country: 'United States',
					zipCode: '98133',
					status: 'active',
				},
				{
				  	id: 2584,
					corporationName: 'Montefiore St. Luke’s Cornwall',
					npi: '2344575683',
					shortCode: '4670',
					taxId: '653757655',
					addressOne: '401 Olympia Ave. NE, #305 Box #48, Renton, WA 98056',
					addressTwo: null,
					city: 'Renton',
					state: 'Washington',
					country: 'United States',
					zipCode: '98056',
					status: 'active',
				},
				{
				  	id: 1224,
					corporationName: 'Agile Physical Therapy',
					npi: '3185361061',
					shortCode: '5715',
					taxId: '155096886',
					addressOne: '19980 10th Ave. NE, Ste. 202 Poulsbo, WA 98370',
					addressTwo: null,
					city: 'Poulsbo',
					state: 'Washington',
					country: 'United States',
					zipCode: '98370',
					status: 'inactive',
				},
				{
				  	id: 5924,
					corporationName: 'Advanced Correctional, Inc.',
					npi: '5917619044',
					shortCode: '8574',
					taxId: '451766921',
					addressOne: '19987 1st Ave. S, Ste. 102 Normandy Park, WA 98148',
					addressTwo: null,
					city: 'Normandy',
					state: 'Washington',
					country: 'United States',
					zipCode: '98148',
					status: 'active',
				},
				{
				  	id: 9344,
					corporationName: 'Maven Clinic',
					npi: '7063995264',
					shortCode: '3214',
					taxId: '714897059',
					addressOne: '230 Grant Rd., Ste. B-2 East Wenatchee, WA 98802',
					addressTwo: null,
					city: 'Wenatchee',
					state: 'Washington',
					country: 'United States',
					zipCode: '98802',
					status: 'active',
				},
			];
			data.forEach((element) => {
				if(element.id == BaseFilters.SearchTerm) {
					targetData = element;
				}
			});
			// console.log("targetData: ", targetData);
			return [targetData];
		}
	}
	static PracticesCorporationProfiles = {
		getData(BaseFilters) {
			return [
				{
					id: 994,
					profileName: 'Molina Healthcare',
					shortCode: '478',
					provider: 'Dr. Katie Sims',
					location: 'Pro Property Maintenance',
					insCompany: 'Anthem Inc',
					ipa: 'Yes',
					status: 'active',
				},
				{
					id: 124,
					profileName: 'Blue Cross Blue Shield',
					shortCode: '754',
					provider: 'Dr. Kathy Pacheco',
					location: 'Mostow Co.',
					insCompany: 'Highmark Group',
					ipa: 'Yes',
					status: 'active',
				},
				{
					id: 725,
					profileName: 'Health Care Service Corporation',
					shortCode: '241',
					provider: 'Dr. Judith Rodriguez',
					location: 'Cut Rite Lawn Care',
					insCompany: 'Centene Corporation',
					ipa: 'No',
					status: 'inactive',
				},
			];
		},
	}
	static PracticesProvider = {
		getData(BaseFilters) {
			return [
				{
					id: 571,
					fullName: 'Dr. James Hall',
					firstName: 'James',
					lastName: 'Hall',
					npi: '65421374',
					shortCode: '987',
					phone: [
						{
							number: '(830) 556-6651',
							type: 'personal',
						},
						{
							number: '(830) 556-6651',
							type: 'home',
						}
					],
					email: [
						'MaryKFreund@gmail.com'
					],
					status: 'Active',
				},
				{
					id: 572,
					fullName: 'Prof. Kenneth Allen',
					firstName: 'Kenneth',
					lastName: 'Allen',
					npi: '65421374',
					shortCode: '987',
					phone: [
						{
							number: '(830) 556-6651',
							type: 'work',
						},
					],
					email: [
						'DanielRHamilton@zoho.com'
					],
					status: 'Inactive',
				},
				{
					id: 573,
					fullName: 'Dr. Eddie Lake',
					firstName: 'Eddie',
					lastName: 'Lake',
					npi: '65421374',
					shortCode: '987',
					phone: [
						{
							number: '(830) 556-6651',
							type: 'work',
						},
					],
					email: [
						'KatieKSims@yahoo.com'
					],
					status: 'Active',
				},
				{
					id: 574,
					fullName: 'Dr. Kurt Bates',
					firstName: 'Kurt',
					lastName: 'Bates',
					npi: '65421374',
					shortCode: '987',
					phone: [
						{
							number: '(830) 556-6651',
							type: 'personal',
						},
					],
					email: [
						'PatriciaTSanders@gmail.com',
						'TSanders2@gmail.com'
					],
					status: 'Active',
				},
			];
		},
		async getOneData(BaseFilters) {
			let targetData;
			let data = [
				{
					id: 571,
					fullName: 'Dr. James Hall',
					firstName: 'James',
					lastName: 'Hall',
					npi: '65421374',
					shortCode: '987',
					phone: [
						{
							number: '(830) 556-6651',
							type: 'personal',
						},
						{
							number: '(830) 556-6651',
							type: 'home',
						}
					],
					email: [
						'MaryKFreund@gmail.com'
					],
					status: 'Active',
				},
				{
					id: 572,
					fullName: 'Prof. Kenneth Allen',
					firstName: 'Kenneth',
					lastName: 'Allen',
					npi: '65421374',
					shortCode: '987',
					phone: [
						{
							number: '(830) 556-6651',
							type: 'work',
						},
					],
					email: [
						'DanielRHamilton@zoho.com'
					],
					status: 'Inactive',
				},
				{
					id: 573,
					fullName: 'Dr. Eddie Lake',
					firstName: 'Eddie',
					lastName: 'Lake',
					npi: '65421374',
					shortCode: '987',
					phone: [
						{
							number: '(830) 556-6651',
							type: 'work',
						},
					],
					email: [
						'KatieKSims@yahoo.com'
					],
					status: 'Active',
				},
				{
					id: 574,
					fullName: 'Dr. Kurt Bates',
					firstName: 'Kurt',
					lastName: 'Bates',
					npi: '65421374',
					shortCode: '987',
					phone: [
						{
							number: '(830) 556-6651',
							type: 'personal',
						},
					],
					email: [
						'PatriciaTSanders@gmail.com',
						'TSanders2@gmail.com'
					],
					status: 'Active',
				},
			];
			data.forEach((element) => {
				if(element.id == BaseFilters.SearchTerm) {
					// targetData.push(element);
					targetData = element;
				}
			});
			// console.log("targetData: ", targetData);
			return [targetData];
		}
	}
	static PracticesCorporationFeeSchedules = {
		getData(BaseFilters) {
			return [
				{
					id: 1637,
					feeScheduleName: 'Fee schedules Name #1',
					inOutNetwork: 'In network',
					startDate: '08/05/19',
					endDate: 'Active',
					status: 'active',
				},
				{
					id: 6473,
					feeScheduleName: 'Fee schedules Name #2',
					inOutNetwork: 'Out of network',
					startDate: '08/05/19',
					endDate: '10/05/22',
					status: 'inactive',
				},
				{
					id: 9866,
					feeScheduleName: 'Fee schedules Name #3',
					inOutNetwork: 'Out of network',
					startDate: '10/06/22',
					endDate: '10/06/23',
					status: 'pending',
				},
				{
					id: 5579,
					feeScheduleName: 'Fee schedules Name #4',
					inOutNetwork: 'In network',
					startDate: '03/28/18',
					endDate: '05/18/20',
					status: 'expired',
				},
			];
		},
	}
	static PracticesCorporationFeeScheduleOverrides = {
		getData(BaseFilters) {
			return [
				{
					id: 1678,
					procedureCode: '90999',
					inOutNetwork: 'In network',
					name: 'Cala Foods',
					description: 'Some description',
					feeAmount: '$9,212.22',
				},
				{
					id: 7382,
					procedureCode: '1527B',
					inOutNetwork: 'Out of network',
					name: 'Magna Architectural Design',
					description: 'Pacific Stereo',
					feeAmount: '$3,756.38',
				},
				{
					id: 8109,
					procedureCode: '76140',
					inOutNetwork: 'Out of network',
					name: 'Total Network Development',
					description: 'Some description',
					feeAmount: '$1,905.17',
				},
				{
					id: 3478,
					procedureCode: '81479',
					inOutNetwork: 'In network',
					name: 'Rhodes Furniture',
					description: 'Magna Architectural Design',
					feeAmount: '$4,608.82',
				},
			];
		},
	}
	static PracticesProviderProfile = {
		getData(BaseFilters) {
			return [
				{
					id: 564867,
					profileName: 'Molina Healthcare',
					shortCode: '478',
					corporation: 'Auto Works',
					location: 'Pro Property Maintenance',
					insCompany: 'Anthem Inc',
					ipa: 'Yes',
					status: 'Active',
				},
				{
					id: 987723,
					profileName: 'Blue Cross Blue Shield ',
					shortCode: '478',
					corporation: "Johnson's General Stores",
					location: 'Mostow Co.',
					insCompany: 'Highmark Group',
					ipa: 'Yes',
					status: 'Active',
				},
				{
					id: 687117,
					profileName: 'Health Care Service Corporation ',
					shortCode: '478',
					corporation: 'Cala Foods',
					location: 'Cut Rite Lawn Care',
					insCompany: 'Centene Corporation',
					ipa: 'No',
					status: 'Inactive',
				},
			];
		},
	}
	static PracticesProviderSpecialties = {
		getData(BaseFilters) {
			return [
				{
					id: 123,
					specialtyName: 'I’ve created a note as an example',
				},
				{
					id: 234,
					specialtyName: 'I’ve created a note as an example',
				},
				{
					id: 758,
					specialtyName: 'I’ve created a note as an example',
				},
				{
					id: 345,
					specialtyName: 'I’ve created a note as an example',
				},
			];
		},
	}
	static PracticesProfiles = {
		getData(BaseFilters) {
			return [
				{
					id: 39872,
					profileName: 'Molina Healthcare',
					shortCode: '478',
					provider: 'Dr. Katie Sims',
					corporation: 'Specialty Restaurant Group',
					location: 'Pro Property Maintenance',
					insCompany: 'Anthem Inc',
					ipa: 'Yes',
					status: 'Active',
					hasEFT: true,
    				hasERA: false,
				},
				{
					id: 57985,
					profileName: 'Blue Cross Blue Shield',
					shortCode: '754',
					provider: 'Dr. Kathy Pacheco',
					corporation: "Cala Foods",
					location: 'Mostow Co.',
					insCompany: 'Highmark Group',
					ipa: 'Yes',
					status: 'Active',
					hasEFT: true,
    				hasERA: false,
				},
				{
					id: 66878,
					profileName: 'Health Care Service Corporation',
					shortCode: '241',
					provider: 'Dr. Judith Rodriguez',
					corporation: "Luskin's",
					location: 'Cut Rite Lawn Care',
					insCompany: 'Centene Corporation',
					ipa: 'No',
					status: 'Inactive',
					hasEFT: true,
    				hasERA: false,
				},
				{
					id: 35738,
					profileName: 'Metropolitan',
					shortCode: '587',
					provider: 'Dr. James Hall',
					corporation: "Johnson's General Stores",
					location: 'Sofa Express',
					insCompany: 'Caresource',
					ipa: 'No',
					status: 'Active',
					hasEFT: true,
    				hasERA: false,
				},
			];
		},
		async getOneData(BaseFilters) {
			let targetData;
			let data = this.getData(BaseFilters);
			data.forEach((element) => {
				if(element.id == BaseFilters.SearchTerm) {
					// targetData.push(element);
					targetData = element;
				}
			});
			return [targetData];
		}
	}
	static PracticesPlansDetails = {
		getData(BaseFilters) {
			return [
				{
					id: 564867,
					shortCode: '478',
					planName: 'Molina Healthcare',
					inOutNetwork: 'Out of network',
					providerId: '95941',
					startDate: '08/15/19',
					endDate: '10/05/22',
					status: 'Pending',
					providerName: 'Johnny Shelby',
					corporation: 'Some company name',
					location: 'Some location name',
					insCompany: 'Health direct insurance',
				},
				{
					id: 987723,
					shortCode: '478',
					planName: 'Blue Cross Blue Shield',
					inOutNetwork: 'In network',
					providerId: '66175',
					startDate: '08/05/19',
					endDate: '10/05/22',
					status: 'Active',
					providerName: 'Johnny Shelby',
					corporation: 'Some company name',
					location: 'Some location name',
					insCompany: 'Health direct insurance',
				},
				{
					id: 564867,
					shortCode: '478',
					planName: 'Health Care Service Corporation',
					inOutNetwork: 'In network',
					providerId: '96164',
					startDate: '03/28/18',
					endDate: '05/18/20',
					status: 'Inactive',
					providerName: 'Johnny Shelby',
					corporation: 'Some company name',
					location: 'Some location name',
					insCompany: 'Health direct insurance',
				},
			];
		},
		async getOneData(BaseFilters) {
			let targetData;
			let data = this.getData(BaseFilters);
			data.forEach((element) => {
				if(element.id == BaseFilters.SearchTerm) {
					// targetData.push(element);
					targetData = element;
				}
			});
			return [targetData];
		}
		
	}
	static PracticesOPWriteOffs = {
		getData(BaseFilters) {
			return [
				{
					id: 123,
    				procedures: 'Evaluation & Management Services'
				},
				{
					id: 971,
    				procedures: 'Anesthesia Services'
				},
			];
		}
	}
	static PracticesOPOtherRequiredModifiers = {
		getData(BaseFilters) {
			return [
				{
					id: 166,
    				procedureCode: '95044',
					requiredModifiers: [
						'54071',
						'87321',
						'74171'
					]
				},
				{
					id: 259,
    				procedureCode: '1527B',
					requiredModifiers: [
						'74712',
						'21471'
					]
				},
			];
		}
	}
	static PracticesAuthProcedures = {
		getData(BaseFilters) {
			return [
				{
					id: 135, 
					procedureCode: '1527B',
					needReferral: 'No',
					needAuthorization: 'Yes',
				},
				{
					id: 136, 
					procedureCode: '90999',
					needReferral: 'Yes',
					needAuthorization: 'No',
				},
			];
		}
	}
	static PracticesInsurancePlansAuthProcedures = {
		getData(BaseFilters) {
			return [
				{
					id: 135, 
					procedureSource: 'Company Info',
					procedureCode: '1527B',
					needReferral: 'No',
					needAuthorization: 'Yes',
				},
				{
					id: 136,
					procedureSource: 'Insurance Plans',
					procedureCode: '90999',
					needReferral: 'Yes',
					needAuthorization: 'No',
				},
			];
		}
	}
	static PracticesAuthSpecialty = {
		getData(BaseFilters) {
			return [
				{
					id: 135, 
					specialty: 'Allergys',
					needReferral: 'No',
					needAuthorization: 'Yes',
				},
				{
					id: 136, 
					specialty: 'Allergy and immunology',
					needReferral: 'Yes',
					needAuthorization: 'No',
				},
			];
		}
	}
	static PracticesInsurancePlansAuthSpecialty = {
		getData(BaseFilters) {
			return [
				{
					id: 135, 
					specialty: 'Allergys',
					procedureSource: 'Company Info',
					needReferral: 'No',
					needAuthorization: 'Yes',
				},
				{
					id: 136, 
					specialty: 'Allergy and immunology',
					procedureSource: 'Insurance Plans',
					needReferral: 'Yes',
					needAuthorization: 'No',
				},
			];
		}
	}
}